import * as React from 'react';
import { ConfigurationProducts, FilteredOptions, UserSelection } from "../../../../types/types";
import * as Actions from '../../../../store/actions';
import { Menu, Form } from 'semantic-ui-react';
import ProductChoiceField from './ProductChoiceField';
import LouverSelectionField from './LouverSelectionField';
import {withTranslation} from "react-i18next";
import I18n from "../../../../i18n/i18n";
import {STANDARD_LOUVER_VALUES} from "../../../../util/constants";


type Props = {
    filteredOptions: FilteredOptions,
    userSelection: UserSelection,
    setParameter: typeof Actions.setParameter,
    products: ConfigurationProducts | null,
    locale: string
};

type State = {};

class CreateRooftopItem extends React.PureComponent<Props, State> {

    public calculateLouverCount() {

        // Round (calculated by diameter)
        if (this.props.userSelection.system == 1)
        {
            let diameter = this.props.userSelection.diameter;

            switch (diameter) {
                case 100:
                    return 3;
                case 125:
                    return 4;
                case 160:
                    return 5;
                case 200:
                    return 6;
                case 250:
                    return 7;
                case 315:
                    return 8;
                case 400:
                    return 10;
                case 500:
                    return 11;
                case 560:
                    return 13;
                case 600:
                    return 14;
                case 630:
                    return 15;
                case 710:
                    return 17;
                case 800:
                    return 18;
                case 900:
                    return 21;
                case 1000:
                    return 23;
                case 1120:
                    return 25;
                case 1250:
                    return 27;
            }
        }
        else
        {
            // Square (calculated by A and B)
            let sizeA = this.props.userSelection.sizeA;
            let sizeB = this.props.userSelection.sizeB;

            const louverStandards = STANDARD_LOUVER_VALUES;
            const meanLength: number | null = ( sizeA + sizeB ) / 2;
            const comparisonMeter = Math.ceil( meanLength / 100 ) * 100;

            // @ts-ignore
            return louverStandards[comparisonMeter];

            /*let relSize = sizeA;
            if (sizeB > sizeA) {
                relSize = sizeB;
            }*/

            /*if (relSize < 300) {
                return 3;
            } else if (relSize < 400) {
                return 5;
            } else if (relSize < 500) {
                return 7;
            } else if (relSize < 600) {
                return 7;
            } else if (relSize < 700) {
                return 8;
            } else if (relSize < 800) {
                return 9;
            } else if (relSize < 1000) {
                return 10;
            } else if (relSize < 1100) {
                return 11;
            } else if (relSize < 1200) {
                return 12;
            } else if (relSize < 1300) {
                return 12;
            } else if (relSize < 1400) {
                return 13;
            } else if (relSize < 1500) {
                return 14;
            } else if (relSize >= 1500) {
                return 15;
            }*/

        }

        return 0;
    }

    public render() {
        if (this.props.filteredOptions.rooftops.length === 0) {
            return null;
        }

        const rooftop = this.props.products && this.props.products.rooftop ? this.props.products.rooftop : null;
        if ( rooftop === null || rooftop.product === null || rooftop.product.louverMin === null
            || rooftop.product.louverMax === null) {
            return null;
        }

        // const isLouverRooftopRectangular = rooftop && rooftop.product.system === 2 &&
        //     rooftop.product.louverDefault && rooftop.product.louverMin < rooftop.product.louverMax;

        const isLouverRooftop = rooftop && rooftop.product.louverDefault &&
          rooftop.product.louverMin < rooftop.product.louverMax;

        // Calculate louver count
        let louverCount = 0;
        if (isLouverRooftop) {
            louverCount = this.calculateLouverCount();
        }

        return (
            <Menu.Item>
                <Menu.Header>{I18n.t('configurator.component.filterForm.section.rooftops')}</Menu.Header>
                <Menu.Menu>
                    <Menu.Item>
                        <ProductChoiceField
                            updateValue={value => this.props.setParameter('rooftop', value)}
                            locale={this.props.locale}
                            products={this.props.filteredOptions.rooftops}
                            value={this.props.userSelection.rooftop}
                        />
                    </Menu.Item>
                    {isLouverRooftop
                        ? <Menu.Item>
                            <Form.Field>
                                <LouverSelectionField
                                    louverSelectionValues={this.props.filteredOptions.louvers}
                                    //currentLouverSelection={this.props.userSelection.louverSelection}
                                    currentLouverSelection={louverCount}
                                    //minLouverCnt={rooftop.product.louverMin}
                                    //maxLouverCnt={rooftop.product.louverMax}
                                    minLouverCnt={3}
                                    maxLouverCnt={27}
                                    updateValue={value => this.props.setParameter('louverSelection', value)}
                                    warningShouldBeDisplayed={this.props.userSelection.louverSelIntModified}
                                />
                            </Form.Field>
                        </Menu.Item>
                        : null}
                </Menu.Menu>
            </Menu.Item>
        );
    }
}

export default withTranslation()(CreateRooftopItem);
